import React, { ReactNode, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardFooter,
  Input,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import Link from "next/link";

import DateText from "~/components/DateText";
import { NewTicketButton } from "~/components/NewTicketButton";
import { JiraIssueList } from "~/components/JiraIssueList";
import { OpenAnyIDForm } from "~/components/OpenAnyIDForm";
import useApi from "~/hooks/use-api";

// TODO: Add some Zendesk stats - queue size, ave wait time, # new tickets

interface JiraSprint {
  id: number;
  goal: string;
  name: string;
  board: {
    location: {
      /** eg "Ordering" */
      displayName: string;
      /** eg ORD */
      projectKey: string;
    };
  };
  startDate: string;
  endDate: string;
}

const colProps = {
  className: "mb-3",
  xs: "12",
  sm: "12",
  md: "12",
  lg: "6",
  xl: "6",
};

const cardBodyStyle = {
  height: 300,
  overflow: "auto",
};

function Title(props: { children: React.ReactNode }) {
  return <strong>{props.children}</strong>;
}

export default function DashboardPage() {
  return (
    <Container>
      <Row className="my-3">
        <Col>
          <h3>Dashboard</h3>
        </Col>
        <Col className="text-right">
          <OpenAnyIDForm goButton />
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6" lg="9">
          <Row>
            <Col {...colProps}>
              <HelpCard />
            </Col>

            <Col {...colProps}>
              <LastSprintCard />
            </Col>

            <Col {...colProps}>
              <ThisSprintCard />
            </Col>

            <Col {...colProps}>
              <RoadmapCard />
            </Col>

            <Col {...colProps}>
              <BugsCard
                heading="Known Bugs"
                title="Active/Open Bugs"
                jql="issuetype = Bug AND resolutiondate is EMPTY ORDER BY created DESC"
              />
            </Col>

            <Col {...colProps}>
              <BugsCard
                heading="Fixed Bugs"
                title="Bugs Resolved Last 21d"
                jql="issuetype = Bug AND resolved >= -21d ORDER BY resolved DESC"
              />
            </Col>
          </Row>
        </Col>

        <Col xs="12" md="6" lg="3">
          <OrganizationsCard />
        </Col>
      </Row>
    </Container>
  );
}

function HelpCard() {
  return (
    <>
      <Card>
        <CardHeader tag="h4">Get Help</CardHeader>
        <CardBody style={cardBodyStyle}>
          <ListGroup flush>
            <ListGroupItem
              tag="a"
              href="https://wiki.loke.global/doc/loke-glossary-eMkHF0Zi1B"
            >
              LOKE Glossary
            </ListGroupItem>
            <ListGroupItem
              tag="a"
              href="https://wiki.loke.global/collections/b3a2d72e-2aa0-4a7b-b667-de9cdef26155"
            >
              Product Documentation
            </ListGroupItem>
          </ListGroup>
        </CardBody>
      </Card>
    </>
  );
}

function ExpandableSprintGoal(props: { goal: string }) {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <div
      className={expanded ? "sprint-goal-expanded" : "sprint-goal-collapsed"}
      onClick={toggleExpanded}
    >
      <style jsx>{`
        .sprint-goal-collapsed {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }
        .sprint-goal-expanded {
          white-space: pre-wrap;
          cursor: pointer;
        }
      `}</style>
      {props.goal}
    </div>
  );
}

function PreviousSprint(props: { sprint: JiraSprint }) {
  const { sprint } = props;
  return (
    <div key={sprint.id}>
      <style jsx>{`
        .sprint-name {
        }
        .sprint-date {
          font-size: 0.8em;
          color: #666;
        }
        .sprint-goal {
          font-size: 0.8em;
        }
      `}</style>
      <Link
        href="/tools/jira/sprints/[sprintId]"
        as={`/tools/jira/sprints/${sprint.id}`}
      >
        <a title={sprint.goal || "None provided"} className="sprint-name">
          <strong>{sprint.board.location.displayName}</strong> {sprint.name}
        </a>
      </Link>{" "}
      <div className="sprint-date">
        <DateText date={sprint.startDate} format="date" /> -{" "}
        <DateText date={sprint.endDate} format="date" />
      </div>
      <div className="sprint-goal">
        <ExpandableSprintGoal goal={sprint.goal || "No goal provided"} />
      </div>
    </div>
  );
}

function LastSprintCard() {
  const { data: sprints } = useApi<JiraSprint[]>("/api/jira/closedsprints", {
    revalidateOnFocus: false,
  });

  return (
    <Card>
      <CardHeader tag="h4">Just Out</CardHeader>
      <CardBody style={cardBodyStyle}>
        <CardText>
          {sprints &&
            sprints.map((sprint) => (
              <>
                <PreviousSprint key={sprint.id} sprint={sprint} />
                <hr />
              </>
            ))}
        </CardText>
      </CardBody>
    </Card>
  );
}

function DetailsRow(props: {
  label: string;
  value?: string;
  children?: ReactNode;
}) {
  return (
    <div>
      <em>{props.label}:</em> {props.value || props.children}
    </div>
  );
}

function CurrentSprintDetails(props: { sprint: JiraSprint }) {
  const { sprint } = props;
  return (
    <CardText className="text-small">
      <style jsx>{`
        .sprint-goal {
          font-size: 0.8em;
          white-space: pre-wrap;
        }
      `}</style>
      <Title>{sprint.board.location.displayName}</Title>
      <DetailsRow label="Sprint" value={sprint.name} />
      <DetailsRow label="Ends">
        <DateText date={sprint.endDate} format="date" />
      </DetailsRow>
      <DetailsRow label="Issues">
        <Link
          href="/tools/jira/sprints/[sprintId]"
          as={`/tools/jira/sprints/${sprint.id}`}
        >
          <a>View</a>
        </Link>
      </DetailsRow>
      <DetailsRow label="Goal">
        <div className="sprint-goal">{sprint.goal || "None provided"}</div>
      </DetailsRow>
    </CardText>
  );
}

function ThisSprintCard() {
  const { data: sprints } = useApi<any[]>("/api/jira/activesprints", {
    revalidateOnFocus: false,
  });

  return (
    <Card>
      <CardHeader tag="h4">Right Now</CardHeader>
      <CardBody style={cardBodyStyle}>
        {sprints &&
          sprints.map((sprint) => (
            <CurrentSprintDetails key={sprint.id} sprint={sprint} />
          ))}
      </CardBody>
    </Card>
  );
}

function RoadmapCard() {
  return (
    <Card>
      <CardHeader tag="h4">Up Now &amp; Next</CardHeader>
      <CardBody style={cardBodyStyle}>
        <JiraIssueList
          title="Upcoming on Roadmap"
          jql="issuetype = Epic AND resolution IS EMPTY AND status != Open ORDER BY status"
        />
      </CardBody>
    </Card>
  );
}

function BugsCard(props: { heading: string; title: string; jql: string }) {
  return (
    <Card>
      <CardHeader tag="h4">{props.heading}</CardHeader>
      <CardBody style={cardBodyStyle}>
        <JiraIssueList title={props.title} jql={props.jql} />
      </CardBody>
      <CardFooter className="text-muted">
        <NewTicketButton
          label="Report a New Bug"
          title="Report Bug"
          ticketType="bug-report"
          buttonClass="btn-block"
          initValues={{}}
        />
      </CardFooter>
    </Card>
  );
}

interface Organization {
  uid: string;
  name: string;
  created: string;
  disabled: string;
}

function OrganizationsCard() {
  const { data: orgs } = useApi<Organization[]>("/api/organization", {
    revalidateOnFocus: false,
  });
  const recent = orgs
    ? orgs
        .filter((o) => !o.disabled)
        .sort((a, b) => (a.created < b.created ? 1 : -1))
    : [];

  return (
    <Card>
      <CardHeader tag="h4">Organisations</CardHeader>
      <CardBody>
        <Link href="/orgs/new">
          <a className="btn btn-primary btn-block">Create New</a>
        </Link>
        <hr />
        <OrganizationList orgs={recent} />
      </CardBody>
    </Card>
  );
}

function OrganizationList(props: { orgs: Organization[] }) {
  const [query, setQuery] = useState("");
  const orgs = (
    !query
      ? props.orgs
      : props.orgs.filter(
          (o) => o.name.toLowerCase().indexOf(query.toLowerCase()) >= 0
        )
  ).slice(0, 30);

  return (
    <>
      <Input
        placeholder="Filter..."
        onChange={(e) => setQuery(e.target.value)}
      />
      <ul>
        {orgs.map((o) => (
          <li key={o.uid}>
            <Link href="/orgs/[org]" as={"/orgs/" + o.uid}>
              <a>{o.name}</a>
            </Link>
          </li>
        ))}
        <style jsx>{`
          ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
          }
          li {
            padding-top: 5px;
            padding-bottom: 5px;
          }
        `}</style>
      </ul>
    </>
  );
}
