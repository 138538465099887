import React from "react";
import { Formik, Form, Field } from "formik";
import { useRouter } from "next/router";

interface Values {
  q: string;
}

interface Props {
  goButton?: boolean;
}

export function OpenAnyIDForm(props: Props) {
  const router = useRouter();
  const handleSubmit = async (values: Values) => {
    if (!values.q) return;
    try {
      const result = await fetch(`/api/go/${values.q}`);
      if (!result.ok) {
        throw new Error((await result.json()).message);
      }

      const { url } = await result.json();

      router.push(url);
    } catch (err) {
      const e = err as Error;
      alert(e.message);
    }
  };

  return (
    <Formik initialValues={{ q: "" }} onSubmit={handleSubmit}>
      <Form>
        <div style={{ display: "flex" }}>
          <Field
            type="text"
            name="q"
            className="form-control"
            placeholder="Enter ID of org, order, customer..."
          />
          {props.goButton && (
            <button type="submit" className="btn btn-primary ml-3">
              Go
            </button>
          )}
        </div>
      </Form>
    </Formik>
  );
}
