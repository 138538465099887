import React from "react";

import useApi from "~/hooks/use-api";
import { Badge, Spinner, Table } from "reactstrap";
import Link from "next/link";

interface Props {
  /** Query */
  jql: string;
  title?: string;
}

interface JiraIssue {
  id: number;
  key: string;
  fields: {
    summary: string;
    assignee?: {
      displayName: string;
    };
    issuetype: {
      name: string;
    };
    labels: string[];
    priority: {
      name: string;
    };
    project: {
      name: string;
      key: string;
    };
    reporter?: {
      displayName: string;
    };
    resolution?: {
      name: string;
    };
    resolutiondate: string;
    description: string;
    status: { name: string };
  };
}

function colorFromStatus(status: string) {
  if (status === "Waiting for Engineering") return "warning";
  if (status === "Resolved") return "success";
  if (status === "Review") return "primary";
  if (status === "Blocked") return "danger";
  if (status === "Backlog") return "secondary";
  if (status === "In Development") return "primary";
  return "info";
}

function StatusBadge(props: { status: string }) {
  const color = colorFromStatus(props.status);

  return (
    <Badge pill color={color}>
      {props.status}
    </Badge>
  );
}

function IssueTypeBadge(props: { issue: JiraIssue }) {
  const issue = props.issue;
  const type = issue.fields.issuetype.name;

  if (type === "Story") {
    return <Badge color="success">{type}</Badge>;
  }
  if (type === "Epic") {
    return <Badge color="dark">{type}</Badge>;
  }
  if (type === "Bug") {
    const bugColor = ["Low", "Normal"].includes(issue.fields.priority.name)
      ? "warning"
      : "danger";
    return <Badge color={bugColor}>Bug: {issue.fields.priority.name}</Badge>;
  }

  return <Badge color="info">{type}</Badge>;
}

function IssueRow(props: { issue: JiraIssue }) {
  const issue = props.issue;

  return (
    <div>
      <hr />
      <style jsx>{`
        .nowrap {
          white-space: nowrap;
        }
        .small {
          font-size: 0.9em;
        }
      `}</style>
      <div className="small">
        <IssueTypeBadge issue={issue} /> {issue.fields.summary}
      </div>
      <div className="small">
        <Link
          href={"/tools/jira/issues/[issueId]"}
          as={`/tools/jira/issues/${issue.id}`}
        >
          <a className="nowrap">{issue.key}</a>
        </Link>
      </div>
      <StatusBadge status={issue.fields.status.name} />
    </div>
  );
}

function IssueList(props: { issues: JiraIssue[] }) {
  const { issues } = props;

  if (!issues) return <Spinner />;

  return (
    <>
      {issues.map((i: JiraIssue) => (
        <IssueRow key={i.key} issue={i} />
      ))}
    </>
  );
}

export function JiraIssueList(props: Props) {
  const { jql, title } = props;

  const searchParams = new URLSearchParams();
  searchParams.append("jql", jql);

  const { data: issues } = useApi<JiraIssue[]>(
    "/api/jira/query?" + searchParams.toString(),
    { revalidateOnFocus: false }
  );

  if (!issues) return <Spinner />;

  return (
    <div>
      <strong>
        {title} ({issues.length})
      </strong>
      <IssueList issues={issues} />
    </div>
  );
}
